









































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Messages } from "@/models/enums/messages.enum";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponseListInsuranceAmortize } from "@/models/interface/leasing.interface";
import { leasingServices } from "@/services/leasing.service";
import { contactServices } from "@/services/contact.service";
interface OptionModel {
  name: string;
  id?: string;
}
@Component({
  mixins: [MNotificationVue],
})
export default class Amortize extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  page = 1;
  limit = 10;
  form!: WrappedFormUtils;
  totalElements = 0;
  dataSource = {} as ResponseListInsuranceAmortize;
  dataListUnitCode = [] as OptionModel[];
  dataListLeasingNumber = [] as OptionModel[];
  dataListPolicyNumber = [] as OptionModel[];
  dataListInsuranceName = [] as OptionModel[];
  loading = {
    find: false,
    unitCode: false,
    leasingNumber: false,
    policyNumber: false,
    insuranceName: false,
    download: false,
  };
  downloadParams = {
    companyName:
      this.$store.state.authStore.authData.companyName ||
      "PT. SATRIA PIRANTI PERKASA",
    unitCode: "ALL",
    leasingNumber: "ALL",
    policyNumber: "ALL",
    insuranceName: "ALL",
    periodFrom: "ALL",
    periodTo: "ALL",
    search: "",
  };
  columnsTable = [
    {
      title: this.$t("lbl_insurance_name"),
      dataIndex: "insuranceName",
      key: "insuranceName",
      width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_leasing_no"),
      dataIndex: "leasingNumber",
      key: "leasingNo",
      width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_policy_number"),
      dataIndex: "policyNumber",
      key: "policyNumber",
      width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_transaction_no"),
      dataIndex: "transactionNumber",
      key: "transactionNo",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_transaction_date"),
      dataIndex: "transactionDate",
      key: "transactionDate",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_insurance_date"),
      dataIndex: "insuranceDate",
      key: "insuranceDate",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_period"),
      dataIndex: "installment",
      key: "installment",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_premi_amount"),
      dataIndex: "premiumAmount",
      key: "premiAmount",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_amortize_amount"),
      dataIndex: "amortizeAmount",
      key: "amortizeAmount",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_accumulation_amortize_amount"),
      dataIndex: "accumulationAmortize",
      key: "accumulationAmortizeAmount",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_nett_book_value"),
      dataIndex: "netBookValue",
      key: "netBookValue",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
  ] as ColumnTableCustom;
  formRules = {
    unitCode: {
      label: "lbl_unit_code",
      name: "lbl_unit_code",
      placeholder: "lbl_unit_code",
      decorator: ["unitCode"],
    },
    leasingNumber: {
      label: "lbl_leasing_number",
      name: "lbl_leasing_number",
      placeholder: "lbl_leasing_number",
      decorator: ["leasingNumber"],
    },
    policyNumber: {
      label: "lbl_policy_number",
      name: "lbl_policy_number",
      placeholder: "lbl_policy_number",
      decorator: ["policyNumber"],
    },
    insuranceName: {
      label: "lbl_insurance_name",
      name: "lbl_insurance_name",
      placeholder: "lbl_insurance_name",
      decorator: ["insuranceName"],
    },
    periodFrom: {
      label: "lbl_period_from",
      name: "lbl_period_from",
      placeholder: "lbl_period_from",
      decorator: ["periodFrom"],
    },
    periodTo: {
      label: "lbl_period_to",
      name: "lbl_period_to",
      placeholder: "lbl_period_to",
      decorator: ["periodTo"],
    },
  };
  mounted() {
    this.initOption();
  }
  initOption() {
    this.getListUnitCode("");
    this.getListLeasingNumber("");
    this.getListPolicyNo("");
    this.getListInsuranceName("");
  }
  getListInsuranceName(value: string): void {
    let params = {
      search: `supplierData.supplierType~*Asuransi*_AND_supplier~true_AND_active~true`,
    } as RequestQueryParamsModel;
    params.search += `_AND_firstName~*${value}*_OR_lastName~*${value}*`;
    this.loading.insuranceName = true;
    contactServices
      .listContactData(params)
      .then(res => {
        this.dataListInsuranceName = res.data.map(item => {
          return {
            name: item.firstName || "",
            id: item.id,
          };
        });
      })
      .finally(() => (this.loading.insuranceName = false));
  }
  getListPolicyNo(value: string): void {
    let params = {} as RequestQueryParamsModel;
    if (value) params.search = `policyNumber~*${value}*`;
    this.loading.policyNumber = true;
    leasingServices
      .listLeasing(params)
      .then(res => {
        let dataTemp = [] as string[];
        res.data.forEach(item => {
          if (!dataTemp.includes(item.policyNumber) && item.policyNumber)
            dataTemp.push(item.policyNumber);
        });
        const dataOption = dataTemp.map(item => {
          return {
            name: item,
          };
        });
        this.dataListPolicyNumber = dataOption;
      })
      .finally(() => (this.loading.policyNumber = false));
  }
  getListLeasingNumber(value: string): void {
    let params = {} as RequestQueryParamsModel;
    if (value) params.search = `leasingNumber~*${value}*`;
    this.loading.leasingNumber = true;
    leasingServices
      .listLeasing(params)
      .then(res => {
        const dataOption = res.data.map(item => {
          return {
            name: item.leasingNumber,
          };
        });
        this.dataListLeasingNumber = dataOption;
      })
      .finally(() => (this.loading.leasingNumber = false));
  }
  getListUnitCode(value: string): void {
    let params = {} as RequestQueryParamsModel;
    if (value) params.search = `unitCode~*${value}*`;
    this.loading.unitCode = true;
    leasingServices
      .listLeasingUnitCode(params)
      .then(res => {
        let uniqIds = {};
        const dataOption = res.data.map(item => {
          return {
            name: item.unitCode,
            id: item.id,
          };
        });
        this.dataListUnitCode = dataOption.filter(
          obj => !uniqIds[obj.name] && (uniqIds[obj.name] = true) && obj.name
        );
      })
      .finally(() => (this.loading.unitCode = false));
  }
  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "amortize" });
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  checkValue(value): string {
    if (value && !value.includes("undefined")) {
      return value;
    } else {
      return "ALL";
    }
  }
  checkParams(res) {
    // company is mandatory
    this.downloadParams.companyName = this.$store.state.authStore.authData.companyName;
    this.downloadParams.unitCode = this.checkValue(res.unitCode);
    this.downloadParams.leasingNumber = this.checkValue(res.leasingNumber);
    this.downloadParams.policyNumber = this.checkValue(res.policyNumber);
    this.downloadParams.insuranceName = this.checkValue(res.insuranceName);
    this.downloadParams.periodFrom = res.periodFrom
      ? moment(res.periodFrom).format(DEFAULT_DATE_FORMAT)
      : "ALL";
    this.downloadParams.periodTo = res.periodTo
      ? moment(res.periodTo).format(DEFAULT_DATE_FORMAT)
      : "ALL";
  }
  download(): void {
    this.form.validateFields((err, res) => {
      if (err) return;
      this.checkParams(res);
      const params = {
        assetId: "",
        params: `${this.downloadParams.companyName},${this.downloadParams.unitCode},${this.downloadParams.leasingNumber},${this.downloadParams.policyNumber},${this.downloadParams.insuranceName},${this.downloadParams.periodFrom},${this.downloadParams.periodTo}`,
      } as RequestQueryParamsModel;
      if (this.downloadParams.search)
        params.search = this.downloadParams.search;
      if (res.unitCode)
        params.assetId = this.dataListUnitCode.find(
          dataFind => dataFind.name === res.unitCode
        )?.id;
      this.loading.download = true;
      leasingServices
        .downloadInsuranceAmortize(params)
        .then(response => {
          if (response) {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "insurance_amortize.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
        })
        .finally(() => (this.loading.download = false));
    });
  }

  clear(): void {
    this.form.resetFields();
  }

  searchData(
    value: string,
    type: "unitCode" | "leasingNumber" | "policyNumber" | "insuranceName"
  ): void {
    switch (type) {
      case "unitCode":
        this.getListUnitCode(value);
        break;
      case "leasingNumber":
        this.getListLeasingNumber(value);
        break;
      case "policyNumber":
        this.getListPolicyNo(value);
        break;
      case "insuranceName":
        this.getListInsuranceName(value);
        break;
      default:
        break;
    }
  }
  assignSearch(key: string, value: string, operator: string): string {
    if (key === "periodFrom" && value) {
      this.downloadParams.periodFrom = moment(value).format(
        DEFAULT_DATE_FORMAT
      );
      return (
        operator +
        `transactionDate>=${moment(value)
          .set({ hour: 0, minute: 0, second: 0 })
          .format()}`
      );
    } else if (key === "leasingNumber" && value) {
      this.downloadParams.leasingNumber = value;
      return operator + `leasingNumber~*${value}*`;
    } else if (key === "policyNo" && value) {
      this.downloadParams.policyNumber = value;
      return operator + `policyNumber~*${value}*`;
    } else if (key === "insuranceName" && value) {
      this.downloadParams.insuranceName = value;
      return operator + `insuranceId~*${value}*`;
    } else if (key === "transactionDate" && value) {
      this.downloadParams.periodTo = moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `transactionDate<=${moment(value)
          .set({ hour: 23, minute: 59, second: 59 })
          .format()}`
      );
    } else return "";
  }

  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }

  async findData(pagination): Promise<void> {
    try {
      this.form.validateFields((err, res) => {
        if (err) return;
        const assetId = this.dataListUnitCode.find(
          dataFind => dataFind.name === res["unitCode"]
        );
        const params: RequestQueryParamsModel = {
          assetId: assetId?.id,
          page: pagination ? this.page - 1 : 0,
          limit: this.limit,
          sorts: "createdDate:desc",
        };
        for (const key in this.downloadParam) {
          if (key != "companyName") {
            this.downloadParam[key] = "ALL";
          }
        }
        if (this.dynamicSearch(res)) {
          params.search = this.dynamicSearch(res);
        }
        this.downloadParams.search = params.search as string;
        this.getData(params);
      });
    } catch (error) {
      this.showNotifError(Messages.PROCESS_FAIL);
    }
  }
  async getData(params) {
    this.loading.find = true;
    try {
      const res = await leasingServices.listInsuranceAmortize(params);
      res.data.forEach((element, index) => {
        element["key"] = index;
      });
      this.dataSource = res;
      this.loading.find = false;
    } catch (error) {
      this.loading.find = false;
    }
  }
  responsePageSizeChange(response: ResponsePagination): void {
    this.limit = response.size;
    this.page = 1;
    this.findData(true);
  }

  responseCurrentPageChange(response: ResponsePagination): void {
    this.page = response.page;
    this.findData(true);
  }
}
